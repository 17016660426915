@import '../../../../scss/index.scss';


.wrapper {
  display: flex;

  @media only screen and (max-width: 768px) {
    display: block;
  }
}

.stat {
  flex: 1;
  text-align: center;
  background-color: $smoke;

  @media only screen and (max-width: 768px) {
    padding: $space 0;
    margin-bottom: $space;

    &:last-child {
      margin-bottom: 0;
    }
  }
}