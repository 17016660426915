@import '../../scss/index.scss';

.heading {
  margin-left: -#{$space-xl};
  margin-right: -#{$space-xl};
  text-align: center;
  padding: $space-l 0;
  background-color: #e0032c;
  color: white;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.chart {
  padding: $space-l $space-l $space-l 0;
}

.table {
  margin-top: $space-xl;
  margin-bottom: $space-l;
}

.error {
  text-align: center;
  padding: $space-l;
  font-size: large;
  font-weight: bold;
}