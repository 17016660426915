@import '../../../scss/index.scss';

.wrap {
  align-items: center;
  display: flex;
  height: calc(100vh - 64px); // TODO: FIX
  justify-content: center;
  width: 100%;
}

.content {
  max-width: 500px;
}