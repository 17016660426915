@import '../../scss/index.scss';

.wrap {
  max-height: 500px;
  width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-250px, -250px);
  // text-align: center;
  background-color: $light;
  padding: $space-xl;
  border-radius: rem(8px);

  h1 {
    margin-top: 0;
  }
}

.buttonRow {
  text-align: center;

  & > button {
    margin: $space-xl $space-xl 0 $space-xl;
  }
}