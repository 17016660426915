@import '../../../scss/index.scss';

.wrap {
  align-items: top;
  display: flex;
  height: 100%;
  justify-content: center;
  min-height: calc(100vh - 64px); // TODO: FIX
  width: 100%;
}

.content {
  background-color: #ffffff;
  width: rem(700px);
  max-width: 100%;
  padding: $space-xl;
  padding-top: $space-xl;
  margin-top: $space;
  margin-bottom: $space;
}