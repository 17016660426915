@import '../../../../../scss/index.scss';

.wrap {
  margin-top: $space-xl;
  padding-top: $space-l;
  border-top: 1px solid #eee;
}

.loading {
  text-align: center;
  padding: $space-l 0;
}

.user {
  background-color: $smoke;
  border-radius: rem(8px);
  display: flex;
  justify-content: space-between;
  margin-bottom: $space-s;
  padding: $space $space-l;
}

.action {
  display: flex;

  &>div {
    color: $warning;
    cursor: pointer;
    text-decoration: underline;
    margin-left: $space-l;
  }
}

.add {
  margin-top: $space-xl;

  button {
    width: 100%;
  }
}