@import '../../../scss/index.scss';

.label {
  margin-bottom: $space-s;
}

.fieldWrap {
  width: 100%;
}

.field {
  width: 100%;
}