@import '../../../../../scss/index.scss';

.row {
  display: flex;

  & > div {
    flex: 1;
    max-width: 50%;
    padding: 0 $space;
  }
}

.submit {
  margin-top: $space-xl;

  button {
    width: 100%;
  }
}

.title {

  h1 {
    margin-bottom: 0;
  }

  p {
    margin-top: $space;
    font-size: 14px;
  }
}