@import '../../scss/index.scss';

.wrap {
  background-color: $light;
  border-radius: rem(8px);
  display: flex;
  flex-direction: column;
  margin-bottom: 10rem;
  padding: $space-xl;
  width: rem(350px);

  > div {
    margin-bottom: 1rem;
  }
}

.introText {
  text-align: center;
  margin-top: 0;
}