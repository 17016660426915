@import '../../scss/index.scss';

.wrapper {
  margin: $space-xxl 0 $space-l;
}

.list {
  margin-bottom: $space-l;
}

.email {
  background-color: $smoke;
  border-radius: rem(8px);
  display: flex;
  justify-content: space-between;
  margin-bottom: $space-s;
  padding: $space $space-l;
}

.delete {
  color: $warning;
  cursor: pointer;
  text-decoration: underline;
}

.form {}

.button {
  margin-top: $space-l !important;
  width: 100%;
}