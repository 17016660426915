@import '../../scss/index.scss';

.wrapper {
  margin: $space-xxl 0 $space-l;
}

.row {
  display: flex;
  width: 100%;

  >div {
    flex: 1;
  }

  @media only screen and (max-width: 768px) {
    display: inline-block;
  }
}

.month {
  padding-right: $space;

  @media only screen and (max-width: 768px) {
    padding-right: 0;
  }
}

.unit {
  padding-left: $space;

  @media only screen and (max-width: 768px) {
    padding-left: 0;
  }
}

.button {
  width: 100%;

  @media only screen and (max-width: 768px) {
    margin-left: 0;
  }
}