@import '../../../../../../../scss/index.scss';

.field {
  padding-bottom: $space-l;
}

.submit {
  padding-top: $space-l;

  button {
    width: 100%;
  }
}